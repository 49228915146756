import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import '../../MainStyle.css';

const LoadingDialog = ({intentData}) => {

    return (
        <div className={`ps-fx ps-tp ps-btm h-100vh res-wth z-i--100 bg-l-black v-center ${intentData.isLoadingShow ? 'activeDialog' : ''}`}>
            <div className='h-30-p w-30-p lodr-v'></div>
        </div>
    );
};

export default LoadingDialog;