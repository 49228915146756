import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog';
import '../../MainStyle.css';
import { WEBSITE_NAME,redirectTo,generateReferalURL } from '../modals/Constants';
import { getStorage } from '../modals/Storage';
import ShareOptionsView from '../other-components/ShareOptionsView';

function InviteLink(){
  const navigate = useNavigate();

  const [pageConst, setConstants] = useState({
    pageTitle: "Invitation Link",
    isLoadingShow: false,
    toastDialogShow: false,
    toastMessage: "",
    recordList: [],
  });

  const topBarClickAction = (data) =>{
  }

    
    const updateToastDialogState = (data,msg) => {
        setConstants(previousState => {
          return { ...previousState, toastDialogShow: data }
        });
  
        setConstants(previousState => {
          return { ...previousState, toastMessage: msg }
        });
    }

    useEffect(() => {
        if(!getStorage("uid")){
          navigate('/login', { replace: true });
        }
      }, []);

    return (
      <div className="v-center bg-black">
        <div className="h-100vh res-wth app-bg">
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

          <div className='pr-v ovf-scrl-y hide-sb zoomAnimView'>
            <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>

            <div className="col-view mg-b-15">

              <div className='col-view v-center pd-5-15 mg-t-45'>
                  <span className='cl-drk-black ft-sz-18 mg-t-10'>My Invite Link</span>

                  <div className='w-100 v-center pd-10-15 ft-sz-20 br-a-l-white br-5 mg-t-15'>
                    <span className='cl-drk-black'>{generateReferalURL(getStorage("uid"))}</span>
                  </div>

                  <ShareOptionsView updateState={updateToastDialogState} />

                  <span className='cl-black ft-sz-13 mg-t-10'>{WEBSITE_NAME} rules and regulations prohibit multiple accounts. You may be blocked if you use multiple accounts or conduct suspicious activities.</span>
              </div>

            </div>

          </div>
        </div>
      </div>
    );
}

export default InviteLink;